.App {
  background-repeat: repeat;
  height: 100%;
}

.Content {
  position: relative;
  padding-top: 0.1em;
  color: white;
  margin-left: 1em;
  height: 100%;
}

.new {
  width: 2em
}

.hot {
  width: 3em
}

.earth {
  position: absolute;
  right: 1em;
  top: 1em;
}

@media only screen and (max-width: 768px) {
  .earth {
    position: absolute;
    right: 1em;
    top: 1em;
    width: 5em;
  }
}

.footer {
  position: absolute;
  bottom: 1em;
}

/* A link that has not been visited */
a:link {
  color: pink;
}
/* A link that has been visited */
a:visited {
  color: greenyellow;
}
/* A link that is hovered on */
a:hover {
  color: red;
}
/* A link that is selected */
a:active {
  color: yellow;
}